<template>
  <div>
    <div class="wrapper wrapper-full-page">
      <div class="full-page" :class="pageClass">
        <div class="content">
          <zoom-center-transition
            :duration="pageTransitionDuration"
            mode="out-in"
          >
            <router-view></router-view>
          </zoom-center-transition>
        </div>
        <footer class="footer">
          <div class="container-fluid">
            <ul class="nav">
              <li class="nav-item">
                <a
                  :href="url"
                  target="_blank"
                  rel="noopener"
                  class="nav-link"
                >
                  {{ storeName }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="https://mrzakaria.com/terms.html"
                  target="_blank"
                  rel="noopener"
                  class="nav-link"
                >
                  {{ $t('Terms of Service') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="https://mrzakaria.com/privacy.html"
                  target="_blank"
                  rel="noopener"
                  class="nav-link"
                >
                  {{ $t('Privacy Policy') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="https://mrzakaria.com/refund.html"
                  target="_blank"
                  rel="noopener"
                  class="nav-link"
                >
                  {{ $t('Refund Policy') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="https://mrzakaria.com/contact.html"
                  target="_blank"
                  rel="noopener"
                  class="nav-link"
                >
                  {{ $t('Contact Us') }}
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="https://mrzakaria.com/about.html"
                  target="_blank"
                  rel="noopener"
                  class="nav-link"
                >
                  {{ $t('About Us') }}
                </a>
              </li>
            </ul>
            <div class="copyright">
              <span style="padding: 0px 10px">
                {{ $t('Powered by') }} &nbsp; <i class="tim-icons icon-heart-2"></i> &amp; <a href="https://erpstore.mrzakaria.com/" target="_blank" rel="noopener">{{ $t('ERP Store') }}</a> &copy; {{ year }}.
              </span>
            </div>
            <div class="copyright">
              <base-input>
                <el-select
                  v-model="myLang"
                  class="select-primary"
                  @change="changeLang"
                  style="display: inline-block !important"
                >
                  <el-option
                    v-for="option in langs"
                    class="select-primary"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id"
                  ></el-option>
                </el-select>
              </base-input>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ZoomCenterTransition } from 'vue2-transitions';
import { Select, Option } from 'element-ui';

export default {
  components: {
    ZoomCenterTransition,
    [Option.name]: Option,
    [Select.name]: Select
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page',
      langs: [
        {id: 'en', name: 'English'},
        {id: 'nl', name: 'Nederlands'},
        {id: 'fr', name: 'Française'},
        {id: 'es', name: 'Español'},
        {id: 'ar', name: 'العربية'},
      ],
      myLang: 'en'
    };
  },
  computed: {
    ...mapGetters([
      'getResources'
    ]),
    storeName() {
      return this.getResources.store;
    },
    url() {
      return this.getResources.url;
    },
    title() {
      return `${this.$route.name} Page`;
    }
  },
  methods: {
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    },
    changeLang(){
      this.$i18n.locale = this.myLang;
      localStorage.setItem('lang', this.myLang);
      // if(this.myLang == 'ar'){
      //   this.$rtl.enableRTL();
      // }else{
      //   this.$rtl.disableRTL();
      // }
    }
  },
  beforeDestroy() {
    this.closeMenu();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  mounted() {
    this.myLang = (localStorage.getItem('lang'))? localStorage.getItem('lang'):'en';
    this.changeLang();
    this.setPageClass();
  },
  watch: {
  }
};
</script>
<style lang="scss">
.navbar.auth-navbar {
  top: 0;
}

$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>
